import axios from "axios";
import React, { Context, useEffect } from "react";

const domainName: string = process.env.REACT_APP_AUTH_DOMAIN ?? "";
const clientId: string = process.env.REACT_APP_CLIENT_ID ?? "";
const redirectUri: string = process.env.REACT_APP_AUTH_REDIRECT_URI ?? "";


type AuthProviderProps = {
    children: JSX.Element
}

type AuthContextValue = {
    getLoginUrl?: () => string,
    getToken?: () => string,
}

const AuthContext: Context<AuthContextValue> = React.createContext<AuthContextValue>({});

export const useAuth = () => {
    return React.useContext(AuthContext);
};

const AuthProvider = ({children}: AuthProviderProps) => {

    useEffect(() => {
        if (redirectUri == window.location.origin) {
            const urlParams = new URLSearchParams(window.location.search);
            const code = urlParams.get("code");
            if (code != null) {
                getTokenFromAuthCode(code);
            }
        }
    });


    const getLoginUrl = (): string => {
        const url = new URL("/login", domainName);
        url.searchParams.append("response_type", "code");
        url.searchParams.append("client_id", clientId);
        url.searchParams.append("redirect_uri", redirectUri);
        return url.href;
    }

    const getTokenFromAuthCode = async (authcode: string): Promise<string> => {
        const params = new URLSearchParams();
        params.append("grant_type", "authorization_code");
        params.append("client_id", clientId);
        params.append("redirect_uri", redirectUri);
        params.append("code", authcode);

        let access_token = "";

        await axios.post(domainName+"/oauth2/token", params, {headers: {"content-type": "application/x-www-form-urlencoded"}})
            .then((response) => {
                const tokens = response.data;
                console.log(response.data)
                access_token = tokens.id_token;
                sessionStorage.setItem("access_token", access_token);
                //axios.defaults.headers.common["Authorization"] = `Bearer ${access_token}`;
            })
            .catch((error) => {     
                console.log(error);
            });
        
        return access_token;
    };

    const getToken = (): string => {
        return sessionStorage.getItem("access_token") ?? "";
    };

    const value: AuthContextValue = {
        getLoginUrl: getLoginUrl,
        getToken: getToken
    }

    return <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>;
};

// TODO:
// - Add the sign_in_url with pkce
// - Save pkce in local storage
// - Create a redirect wrapper for the return
// - Figureout how to protect routes with react-router
// - Save token to session storage, for now. Need to put into cookie in future.
// - Use token to get api data 

export default AuthProvider; 