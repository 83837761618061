import React from 'react';
import { Box, Button, Card, CardContent, Typography } from "@mui/material";
import { Link } from "react-router-dom";

function AssessmentResults() {
    return (
        <Box>
            <Card sx={{minWidth: 200}}>
                <CardContent>
                    <Typography sx={{fontSize: 14}} color="text.secondary" gutterBottom>
                        Correct Answers
                    </Typography>
                    <Typography variant="h5">
                        2/3
                    </Typography>
                </CardContent>
            </Card>
            <Card>
                <Link to="/"><Button variant="contained">Restart</Button></Link>
            </Card>
        </Box>
    );
}

export default AssessmentResults;