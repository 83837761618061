import React from 'react';
import { Box, Button, Divider, useMediaQuery, useTheme } from '@mui/material';
import SectionText from './SectionText';
import QuestionList from './QuestionList';
import SectionModel from 'src/model/SectionModel';
import { useParams } from 'react-router-dom';
import 'src/css/Section.css';
import axios from 'axios';
import { useEffect, useState } from 'react';
import LoadingPlaceholder from './LoadingPlaceholder';
import { useAuth } from './AuthProvider';

const Section = () => {
    const theme = useTheme();
    const isLargeWindow = useMediaQuery(theme.breakpoints.up("md"));
    const itemOrientation = isLargeWindow ? "row" : "column";
    const dividerOrientation = isLargeWindow ? "vertical" : "horizontal";

    const [section, setSection] = useState<SectionModel | null>(null);
    const [loading, setLoading] = useState(true);

    const { id } = useParams();
    const { getToken } = useAuth();
    const token = getToken ? getToken() : "";
  
    useEffect(() => { // TODO: Move into creator page
      if (loading) {
        console.log(token);
        axios.get("https://api.platytest.com/latest/section/" + id, {headers: {"Authorization": `Bearer ${token}`}})
        .then((response) => {
            const newSection = response.data;
            newSection.key = "";
            setSection(newSection);
            setLoading(false);
        })
        .catch((error) => { console.log(error) })
      }
    }, [id, loading])


    /* TODO: Move into a submission view
    const sendSection = () => {
        axios.put("https://api.platytest.com/latest/section/", section, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }).then((res) => {
            console.log(res);
        }).catch((err) => {
            console.log(err);
        })
    }
    */

    const getContent = (section: SectionModel) => 
        <Box sx={{flexDirection: itemOrientation}} className='Section-Container' >
            <Box className='SectionText-Container'>
                <SectionText title={section.content.title} prompt={""} content={section.content.text}></SectionText>
            </Box>
            <Box className='.SectionDivider-Container'>
                <Divider orientation={dividerOrientation}></Divider>
            </Box>
            <Box className='SectionQuestion-Container'>
                <QuestionList questions={section.content.questions} />
            </Box>
        </Box>;
    

    const content: JSX.Element | null = section != null ? getContent(section) : null;

    const loadingPlaceholder: JSX.Element = <Box className='SectionLoading-Container'><LoadingPlaceholder/></Box>;

    return loading ? loadingPlaceholder : content;
}

export default Section;