import React from 'react';
import { Box, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@mui/material";
import AnswerModel from "src/model/AnswerModel";
import QuestionModel from "src/model/QuestionModel";

type QuestionProps = {
    question: QuestionModel,
}

const Question = (props: QuestionProps): JSX.Element => {
    const answerFactory = (answer: AnswerModel): JSX.Element => {
        return <Box key={answer.key}>
            <FormControlLabel
                value={answer.key}
                control={<Radio size="small" />}
                label={answer.text}
            />
        </Box>
    }

    const getAnswers = (answers: AnswerModel[]): JSX.Element[] =>
        answers.map((answer) => answerFactory(answer));

    return (
        <Box>
            <FormControl>
                <FormLabel>{props.question.prompt}</FormLabel>
                <RadioGroup>
                    {getAnswers(props.question.answers)}
                </RadioGroup>
            </FormControl>
        </Box>
    );
}

export default Question;