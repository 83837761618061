import React from 'react';
import { Helmet } from 'react-helmet';
import 'src/css/App.css';
import Box from '@mui/material/Box';
import AssessmentContainer from './AssessmentContainer';
import {
  BrowserRouter,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import StartMenu from './StartMenu';
import AssessmentResults from './AssessmentResults';
import Section from './Section';
import Landing from './Landing';
import AuthProvider from './AuthProvider';

const domainName: string = process.env.REACT_APP_AUTH_DOMAIN ?? "";
const clientId: string = process.env.REACT_APP_CLIENT_ID ?? "";
const redirectUri: string = process.env.REACT_APP_AUTH_REDIRECT_URI ?? "";

/*
const Auth0ProviderWithRedirectCallback = ({children, ...props}: Auth0ProviderOptions) => {
  const navigate = useNavigate();
  const onRedirectCallback = (appState?: AppState): void => {
    navigate((appState && appState.returnTo) || window.location.pathname); 
  };
  return (
    <Auth0Provider onRedirectCallback={onRedirectCallback} {...props}>
      {children}
    </Auth0Provider>
  )
};
*/

const App = (): JSX.Element => {
  return (
    <Box className='App'>
      <Helmet>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
        <title>Assessment Prototype</title>
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
        />
      </Helmet>
      <AssessmentContainer>
        <AuthProvider>
          <BrowserRouter>
            <Routes>
              <Route path="/landing" element={<Landing/>}></Route>
              <Route path="/" element={<StartMenu />} />
              <Route path="/section/:id" element={<Section></Section>} />
              <Route path="/results" element={<AssessmentResults />} />
            </Routes>
        </BrowserRouter>
        </AuthProvider>

      </AssessmentContainer>
    </Box>
  );
}

export default App;
