import React from 'react';
import { Box, Button, Link } from "@mui/material";
import { useAuth } from './AuthProvider';


const Landing = () => {
    const { getLoginUrl } = useAuth();


    return <Box>
        <Link href={getLoginUrl ? getLoginUrl() : ""}>
            <Button>
                Login
            </Button>
        </Link>
    </Box>;
}

export default Landing;