import React from 'react';
import "src/css/LoadingPlaceholder.css"

const LoadingPlaceholder = () => <div className="lds-spinner">
    <div>
    </div>
    <div>
    </div>
    <div>
    </div>
    <div>
    </div>
    <div>
    </div>
    <div>
    </div>
    <div>
    </div>
    <div>
    </div>
    <div>
    </div>
    <div>
    </div>
    <div>
    </div>
    <div>
    </div>
</div>

export default LoadingPlaceholder;