import React from 'react';
import { Box } from '@mui/material';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import TextContentModel from 'src/model/TextContentModel';
import 'src/css/AssessmentText.css';

type SectionTextProps = {
    title: string,
    prompt: string,
    content: TextContentModel,
}

const SectionText = (props: SectionTextProps): JSX.Element => {
    return (
        <Box className="AssessmentText-Container">
            <Typography variant='h4'>{props.title}</Typography>
            <Typography variant='subtitle2'>{props.prompt}</Typography>
            <Divider></Divider>
            {props.content.paragraphs.map((content, index) => <Typography key={index} variant='body1'>{content}</Typography>)}
        </Box>
    );
}

export default SectionText;