import React from 'react';
import { Box, Button } from "@mui/material";
import QuestionModel from "src/model/QuestionModel";
import Question from "./Question";
import 'src/css/QuestionList.css';
import { Link } from "react-router-dom";

type QuestionListProps = {
    questions: QuestionModel[]
}

function QuestionList(props: QuestionListProps) {
    return (
        <Box className="QuestionList-Container">
            <Box>
                {props.questions.map((question, index) => <Question key={index} question={question}></Question>)}
            </Box>
            <Box className="QuestionListButton-Container">
                <Link to="/results"><Button variant="contained">Next</Button></Link>
            </Box>
        </Box>
    );
}

export default QuestionList