import React from 'react';
import { Box } from "@mui/system";
import 'src/css/AssessmentContainer.css';

type AssessmentContainerProps = {
    children?: React.ReactNode,
}
const AssessmentContainer = (props: AssessmentContainerProps): JSX.Element => <Box className="Assessment-Container">
    {props.children}
</Box>;

export default AssessmentContainer;