import React from 'react';
import { Box, Button } from "@mui/material";
import { Link, useSearchParams } from "react-router-dom";
import 'src/css/StartMenu.css';
import { useAuth } from './AuthProvider';

const StartMenu = (): JSX.Element => {
    return <Box className="StartMenu-Container">
        <Link to="/section/la-pesadilla"><Button variant="contained">Start Assessment</Button></Link>
    </Box>;
}


export default StartMenu;